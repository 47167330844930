<div class="room-card">
  <div class="header">
    <div class="title">Your Room</div>
    <div class="copy-container" (click)="copyRoomUrl()">
      <span class="copy-text">Copy URL</span>
      <mat-icon class="copy-icon">content_copy</mat-icon>
    </div>
  </div>
  <div class="content">
    <div class="room-url">{{ roomUrl }}</div>
    <a [href]="roomUrl">
      <mat-icon class="copy-icon">open_in_new</mat-icon>
    </a>
  </div>
</div>
