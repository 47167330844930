import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import dayjs from 'dayjs';
import timezonePlugin from 'dayjs/plugin/timezone';
import { ToastrService } from 'ngx-toastr';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import { PLFadeInAnimation, PLFadeInOutAnimation } from '@common/animations';
import {
  AssignmentProposal,
  AssignmentType,
  OpportunityType,
  PLAssignmentRequirement,
} from '@common/assigment-machine/models';
import { EMPLOYMENT_STATUS } from '@common/constants';
import { PLClinicalServiceTypeCode } from '@common/enums/pl-clinical-service-types.enum';
import { PLSubNavigationTabs } from '@common/interfaces/pl-sub-navigation-tabs';
import { PLUtilService, PLSchoolYearsService } from '@common/services';
import { CurrentUserService } from '@modules/user/current-user.service';
import {
  PLModalService,
  PLConfirmDialogService,
  PLGraphQLService,
  PLApiUsStatesService,
} from '@root/index';
import { PLContactClsmModalComponent } from './assignment-card-modals/pl-contact-clsm-modal.component';
import {
  PLAssignmentInterface,
  PLAssignmentStatusEnum as Status,
} from './pl-assignment-manager.model';
import { PLAssignmentManagerService } from './pl-assignment-manager.service';
import { PLAssignmentProposalItemService } from './pl-assignment-proposal-item.service';
import { PLRejectAssignmentModalComponent } from './pl-reject-assignments-modal.component';
import { PLUpdateAssignmentErrorModalComponent } from './pl-update-assignment-error-modal.component';
import {
  FeatureFlagName,
  FeatureFlagsService,
} from '../../common/feature-flags';
import { PLProviderService } from '../providers/pl-provider.service';
dayjs.extend(timezonePlugin);

const TOAST_TIMEOUT = 5000;
@Component({
  selector: 'pl-provider-assignments',
  templateUrl: './pl-provider-assignments.component.html',
  styleUrls: ['./pl-provider-assignments.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [PLFadeInAnimation, PLFadeInOutAnimation],
})
export class PLProviderAssignmentsComponent implements OnInit, OnDestroy {
  // data
  assignments = [];
  currentUser: any;
  assigmentStatusEnum = Status;

  tabs: PLSubNavigationTabs[] = [];
  schoolYearName: string;
  loading = true;
  loadingAvailability = true;
  hasAssignments = false;
  checkChangesTimeout: any;
  saving = false;
  totalHoursProposed = 0;
  maxWeeklyHours = 0;
  empowermentManagerName = '';
  assignmentStatusClasses = {
    [this.assigmentStatusEnum.INITIATED]: 'proposed',
    [this.assigmentStatusEnum.ACTIVE]: 'active',
    [this.assigmentStatusEnum.PENDING]: 'pending',
    [this.assigmentStatusEnum.RESERVED]: 'reserved',
  };
  snackBarMessage = '';
  showReservedAssignment = false;

  constructor(
    public util: PLUtilService,
    private service: PLAssignmentManagerService,
    private proposalItemService: PLAssignmentProposalItemService,
    private currentUserService: CurrentUserService,
    private schoolYear: PLSchoolYearsService,
    private plModal: PLModalService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private plConfirm: PLConfirmDialogService,
    private plGraphQL: PLGraphQLService,
    private plStates: PLApiUsStatesService,
    private plProviderService: PLProviderService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private featureFlagsService: FeatureFlagsService,
    private overlay: Overlay,
  ) {}

  ngOnInit() {
    this.featureFlagsService
      .isFeatureEnabled(FeatureFlagName.showReservedAssignment)
      .subscribe(enabled => {
        this.showReservedAssignment = enabled;
      });

    this.tabs = this.getTabs();
    this.schoolYear.getCurrentSchoolYear().subscribe((res: any) => {
      this.schoolYearName = res.name;
    });
    this.currentUserService
      .getCurrentUser()
      .pipe(first())
      .subscribe((res: any) => {
        this.currentUser = res;
        this.loadAssignments();
        this.loadAvalability();
      });
    this.plProviderService
      .getProviderByUserId(this.currentUser?.uuid)
      .subscribe((provider: any) => {
        if (provider) {
          if (provider.accountOwner) {
            this.empowermentManagerName = `${provider.accountOwner.firstName} ${provider.accountOwner.lastName}`;
          }
        }
      });
    const fn = () => {
      this.checkChangesTimeout = setTimeout(() => {
        this.cdr.markForCheck();
        fn();
      }, 250);
    };
    fn();
  }

  // ----------------------------
  // PUBLIC METHODS
  // ----------------------------
  onClickAccept(assignment: any) {
    let modalRef: any;
    this.proposalItemService
      .updateProposalStatus(assignment.uuid, Status.PENDING)
      .subscribe(
        (res: any) => {
          this.saving = false;
          this.util.log('onClickAccept', { res, STATE: this });
          this.toastr.success(
            'Successfully accepted assignment',
            '🎉 SUCCESS',
            {
              positionClass: 'toast-bottom-right',
              timeOut: TOAST_TIMEOUT,
            },
          );
          this.loadAssignments();
          if (modalRef && modalRef.instance) {
            modalRef.instance.destroy();
          }
        },
        (err: any) => {
          this.saving = false;
          this.util.errorLog('onClickAccept', { err, STATE: this });
          this.toastr.error(`Unable to accept assignment`, '❌ FAILED', {
            positionClass: 'toast-bottom-right',
            timeOut: TOAST_TIMEOUT,
          });
          this.plModal
            .create(PLUpdateAssignmentErrorModalComponent, {
              saveErrors: err.error,
            })
            .pipe(first())
            .subscribe((ref: any) => {
              modalRef = ref;
            });
        },
      );
    this.saving = true;
  }

  onClickDecline(assignment: any) {
    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop',
      panelClass: 'custom-overlay-panel',
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });

    const overlayRef: OverlayRef = this.overlay.create(overlayConfig);
    const componentRef = overlayRef.attach(
      new ComponentPortal(PLRejectAssignmentModalComponent),
    );
    const params: any = {
      providerAssignment: assignment,
      rejectedReasonsOpts: this.service.PROVIDER_REJECTED_REASONS_OPTS,
      onCancel: () => {
        this.saving = false;
        overlayRef.dispose();
      },
      onSaveSuccess: (res: any) => {
        this.saving = false;
        this.toastr.success(`Successfully declined assignment`, '🎉 SUCCESS', {
          positionClass: 'toast-bottom-right',
          timeOut: TOAST_TIMEOUT,
        });
        this.loadAssignments();
        overlayRef.dispose();
      },
      onSaveError: (err: any) => {
        this.saving = false;
        this.toastr.error(`Unable to decline assignment`, '❌ ERROR', {
          positionClass: 'toast-bottom-right',
          timeOut: TOAST_TIMEOUT,
        });
      },
    };
    this.saving = true;

    componentRef.instance.camProposal = params.camProposal;
    componentRef.instance.providerAssignment = params.providerAssignment;
    componentRef.instance.orgDemandList = params.orgDemandList;
    componentRef.instance.rejectedReasonsOpts = params.rejectedReasonsOpts;
    componentRef.instance.onCancel = params.onCancel;
    componentRef.instance.onSaveSuccess = params.onSaveSuccess;
    componentRef.instance.onSaveError = params.onSaveError;

    // modalRef = componentRef.instance;
  }

  onChangeSchoolYear(event: any) {
    this.util.log('onChangeSchoolYear', { event, STATE: this });
  }

  onClickExpandRow(A: any) {
    A.expanded = !A.expanded;
  }

  isExpanded(A: any) {
    return A.expanded;
  }

  getTabs(): PLSubNavigationTabs[] {
    return [
      { label: 'Calendar', href: `/schedule`, replaceHistory: true },
      { label: 'Availability', href: `/availability`, replaceHistory: true },
      { label: 'Assignments', href: `/assignments`, replaceHistory: true },
    ];
  }

  updateMaxAvailableHours() {
    this.plConfirm.show({
      header: 'Stop receiving new requests?',
      content: `
                <div class="margin-large-r">
                    Are you sure? Your total hours desired will be reduced and you will not receive additional assignment requests.
                </div>
            `,
      primaryLabel: 'Confirm',
      secondaryLabel: 'Cancel',
      primaryCallback: () => {
        this.plConfirm.hide();

        this.loadingAvailability = true;

        const payload: any = {
          availabilityPreference: {
            maxWeeklyHours: this.totalHoursProposed,
          },
        };

        this.plGraphQL
          .mutate(GQL_SET_AVAILABILITY, payload, {})
          .pipe(first())
          .subscribe((res: any) => {
            this.maxWeeklyHours =
              res.setAvailabilityPreference.availabilityPreference.maxWeeklyHours;
            this.loadingAvailability = false;
          });
      },
    });
  }

  // ----------------------------
  ngOnDestroy() {
    clearTimeout(this.checkChangesTimeout);
  }

  // ----------------------------
  // PRIVATE METHODS
  // ----------------------------
  private loadAssignments() {
    this.loading = true;
    this.totalHoursProposed = 0;
    this.service.fetchAssignmentProposals().subscribe((resProposals: any) => {
      this.assignments = [];
      resProposals.forEach((proposalRaw: AssignmentProposal) => {
        if (proposalRaw.user === this.currentUser.uuid) {
          const payRate =
            this.currentUser.xProvider.employmentStatus !==
            EMPLOYMENT_STATUS.SUBCONTRACTOR
              ? Number(proposalRaw.pay_rate)
              : 0.0;

          const metRequirements: any[] = proposalRaw.requirements.filter(
            (req: PLAssignmentRequirement) =>
              this.service.isRequirementMet(req),
          );
          const unmetRequirements: any[] = proposalRaw.requirements.filter(
            (req: PLAssignmentRequirement) =>
              !this.service.isRequirementMet(req) && req.options.length,
          );

          const slpOTList = [
            PLClinicalServiceTypeCode.SLT,
            PLClinicalServiceTypeCode.OT,
            PLClinicalServiceTypeCode.PT,
            PLClinicalServiceTypeCode.APE,
          ];

          const isSLPOT = proposalRaw.service_type_codes.some(i =>
            slpOTList.includes(i),
          );

          const clinical_success_manager: string = isSLPOT
            ? proposalRaw.organization.clinical_success_manager_slp_ot
            : proposalRaw.organization.clinical_success_manager_pes_mhc;

          const expiration_time: string = proposalRaw.expiration_time;

          const state_full_name: string = this.plStates.getFromPostalCode(
            proposalRaw.organization.state,
          )
            ? this.plStates.getFromPostalCode(proposalRaw.organization.state)
            : proposalRaw.organization.state;

          const specialties_label =
            this.service.getSpecialtiesLabel(proposalRaw);

          const assignment_type = [
            OpportunityType.DEDICATED,
            OpportunityType.LEGACY_FTE,
          ].includes(proposalRaw.opportunity_type)
            ? AssignmentType.DEDICATED
            : AssignmentType.NON_DEDICATED;

          const assignment: PLAssignmentInterface = {
            assignment_type,
            clinical_success_manager,
            expiration_time,
            metRequirements,
            payRate,
            specialties_label,
            state_full_name,
            unmetRequirements,
            requirements: proposalRaw.requirements,
            uuid: proposalRaw.uuid,
            orgName: proposalRaw.organization.name,
            orgState: proposalRaw.organization.state,
            orgTimezone: proposalRaw.organization.timezone,
            orgSchoolType:
              proposalRaw.organization.organization_type || 'Brick & Mortar',
            estimatedHours: this.service.durationToDecimalHours(
              proposalRaw.hours,
            ),
            estimatedHoursDecimal: this.service.durationToDecimalHoursDecimal(
              proposalRaw.hours,
            ),
            schoolYear: proposalRaw.school_year,
            startDate: proposalRaw.start_date,
            endDate: proposalRaw.end_date,
            serviceLines: proposalRaw.service_lines,
            isFTE: proposalRaw.is_fte,
            isESY: proposalRaw.is_esy,
            service_model: proposalRaw.service_model,
            specialties: proposalRaw.specialties,
            isAssessment: proposalRaw.is_assessment,
            projected_therapy_start_date:
              proposalRaw.projected_therapy_start_date,
            main_service_line_title: proposalRaw.service_lines[0],
            is_on_hold: proposalRaw.is_on_hold,
            on_hold_reason: proposalRaw.on_hold_reason,
          };
          switch (proposalRaw.status) {
            case Status.INITIATED:
              assignment.status = Status.INITIATED;
              this.assignments.push(assignment);
              this.totalHoursProposed += assignment.estimatedHoursDecimal;
              break;

            case Status.PENDING:
              assignment.status = Status.PENDING;
              this.assignments.push(assignment);
              this.totalHoursProposed += assignment.estimatedHoursDecimal;
              break;

            case Status.ACTIVE:
              assignment.status = Status.ACTIVE;
              this.assignments.push(assignment);
              this.totalHoursProposed += assignment.estimatedHoursDecimal;
              break;

            case Status.COMPLETED:
              if (dayjs().diff(proposalRaw.end_date) < 0) {
                this.assignments.push(assignment);
                this.totalHoursProposed += assignment.estimatedHoursDecimal;
              }
              break;
            case Status.RESERVED:
              if (proposalRaw.is_recommitment && this.showReservedAssignment) {
                assignment.status = Status.RESERVED;
                this.assignments.push(assignment);
                this.totalHoursProposed += assignment.estimatedHoursDecimal;
              }

              break;
          }
        } else {
          this.util.log('unmatched proposal', { proposalRaw, STATE: this });
          this.assignments.push({ proposalRaw });
        }
      });
      const statusOrder = [
        'reserved',
        'proposed',
        'initiated',
        'pending',
        'active',
      ];

      this.assignments.sort((a, b) => {
        return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
      });
      this.hasAssignments = this.assignments.length ? true : false;

      this.util.log('assignmentProposals', { STATE: this });
      this.loading = false;
      this.saving = false;
    });
  }

  private loadAvalability() {
    this.loadingAvailability = true;
    this.plGraphQL
      .query(GQL_GET_AVAILABILITY)
      .pipe(first())
      .subscribe((res: any) => {
        if (res.availabilityPreference)
          this.maxWeeklyHours = res.availabilityPreference.maxWeeklyHours;
        this.loadingAvailability = false;
      });
  }
  openContactClsmDialog(assignment): void {
    const dialogRef = this.dialog.open(PLContactClsmModalComponent, {});

    dialogRef
      .afterClosed()
      .pipe(
        filter(result => !!result.value),
        switchMap(result => {
          const requestData = {
            uuid: assignment.uuid,
            is_on_hold: true,
            on_hold_reason: result.value,
          };
          this.setSnackBarMessage(result.isOnHold);
          return this.proposalItemService.contactClsm(requestData);
        }),
        tap(_ => {
          this.saving = false;
          this.snackBar.open(this.snackBarMessage, 'X', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 5000,
          });
          this.loadAssignments();
        }),
      )
      .subscribe();
  }
  setSnackBarMessage(assignmentIsOnHold) {
    if (assignmentIsOnHold) {
      this.snackBarMessage =
        'We’ve informed the CLSM, and updated this assignment';
    } else {
      this.snackBarMessage =
        'The CLSM has been notified and will be in touch shortly';
    }
  }
}

const GQL_GET_AVAILABILITY = `
  {
    availabilityPreference {
      maxWeeklyHours
    }
  }
`;

const GQL_SET_AVAILABILITY = `
  mutation SaveAvailabilityPreference($availabilityPreference: SetAvailabilityPreferenceInputData) {
    setAvailabilityPreference(input: {availabilityPreference: $availabilityPreference}) {
      availabilityPreference {
        maxWeeklyHours
      }
    }
  }
`;
