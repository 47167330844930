import { Injectable } from '@angular/core';

import { TRACKING_TYPE } from '@common/constants';
import { PLClinicalProductCode } from '@common/enums';
import { FeatureFlagName, FeatureFlagsService } from '@common/feature-flags';
import {
  referralGroupingSpreadsheetLabelToValue,
  referralTrackingTypeSpreadsheetLabelToValue,
} from '@common/services/pl-client-referral';
import { PLGraphQLService } from '@root/index';

import { PLAddReferralsLocationYearService } from './pl-add-referrals-location-year.service';
import {
  FTE_IMPORT_VALUE,
  PLAddReferralsDataTableService,
} from './pl-add-referrals-table-data.service';

import {
  Referral,
  Client,
  ClientReferral,
  StudentReferralUploadObject,
} from './pl-client-referral-data-model.service';

@Injectable()
export class PLAddReferralsGraphQLService {
  referralsWithSkippedLocations: StudentReferralUploadObject[] = [];
  newUploadReferralEnabled: boolean = false;
  constructor(
    private tableDataService: PLAddReferralsDataTableService,
    private locationService: PLAddReferralsLocationYearService,
    private pLGraphQL: PLGraphQLService,
    private featureFlagsService: FeatureFlagsService,
  ) {}

  async convertImportedRowsToClientReferrals() {
    const data: StudentReferralUploadObject[] =
      this.tableDataService.finalImportedData;
    const clientReferrals: ClientReferral[] = [];

    this.featureFlagsService
      .isFeatureEnabled(FeatureFlagName.newUploadReferral)
      .subscribe(enabled => {
        this.newUploadReferralEnabled = enabled;
      });

    for (const row of data) {
      const isEvaluationType =
        row.productTypeCode === PLClinicalProductCode.EVAL;

      let locMap = this.tableDataService.locationsMapping[row.location];
      let clientLocationId = this.locationService.selectedLocationID;

      if (this.newUploadReferralEnabled) {
        // during location mapping the user is able to select 'Do not import / Skip' for a location
        // if the feature flag is enabled and the mapping is `skip` then skip uploading the current referral.
        // the server responds with an error message indicating the row was skipped.
        if (locMap.matchedLocation == 'skip') {
          this.referralsWithSkippedLocations.push(row);
          continue;
        }

        clientLocationId = locMap.matchedLocation;
      }

      const client: Client = {
        firstName: row.firstName,
        lastName: row.lastName,
        birthday: row.birthday,
        locationIds: [clientLocationId],
        externalId: row.externalId,
        englishLanguageLearnerStatus: row.englishLanguageLearnerStatus,
        primaryLanguageCode: row.primaryLanguageCode,
        secondaryLanguageCode: row.secondaryLanguageCode,
      };

      for (const field in client) {
        if (client[field] === '') {
          client[field] = null;
        }
      }

      if (row.clientId) {
        client.id = row.clientId;
      }

      const yesNoNullToBoolean = (value: string): boolean => {
        if (value) {
          switch (value.toLowerCase()) {
            case 'yes':
              return true;
            case 'no':
              return false;
          }
        }

        return null;
      };

      const isFte = this.getFteValue(row.isFte as FTE_IMPORT_VALUE);

      const referral: Referral = {
        assessmentPlanSignedOn: row.assessmentPlanSignature,
        dueDate: row.assessmentDueDate,
        duration: !isEvaluationType ? (row.duration as number) : null,
        esy: !isEvaluationType && yesNoNullToBoolean(row.esy) ? true : false,
        frequency: !isEvaluationType ? row.frequency : null,
        grade: !isEvaluationType ? row.grade : null,
        grouping: !isEvaluationType
          ? row.grouping &&
            referralGroupingSpreadsheetLabelToValue(row.grouping)
          : null,
        ignoreDuplicateWarning: !!row.ignoreDuplicateWarning,
        interval: !isEvaluationType
          ? row.interval && row.interval.toLowerCase()
          : null,
        isAac: yesNoNullToBoolean(row.AAC) ? true : false,
        isAsl: yesNoNullToBoolean(row.ASL) ? true : false,
        isDedicated: yesNoNullToBoolean(row.dedicated),
        isDhh: yesNoNullToBoolean(row.DHH) ? true : false,
        isFte: yesNoNullToBoolean(isFte) ? true : false,
        isShortTerm: yesNoNullToBoolean(row.shortTermLeave) ? true : false,
        isVi: yesNoNullToBoolean(row.visuallyImpaired) ? true : false,
        languageId: row.primaryLanguageCode || 'en',
        meetingDate: row.meetingDate,
        notes: row.notes,
        providerTypeCode: row.providerTypeCode,
        productTypeCode: row.productTypeCode,
        schoolYearCode: this.locationService.selectedSchoolYearCode,
        trackingType: row?.trackingType
          ? referralTrackingTypeSpreadsheetLabelToValue(row.trackingType)
          : TRACKING_TYPE.REGULAR,
      };

      for (const field in referral) {
        if (referral[field] === '') {
          referral[field] = null;
        }
      }

      clientReferrals.push({ client, referral });
    }

    return {
      clientReferrals,
      referralsWithSkippedLocations: this.referralsWithSkippedLocations,
    };
  }

  getFteValue(
    fteValue: undefined | FTE_IMPORT_VALUE,
  ): FTE_IMPORT_VALUE | undefined {
    let newFteValue: FTE_IMPORT_VALUE | undefined = fteValue;

    if (
      fteValue === undefined &&
      this.tableDataService.fteCalculatedValue !== FTE_IMPORT_VALUE.EMPTY
    ) {
      newFteValue = this.tableDataService.fteCalculatedValue;
    }

    return newFteValue;
  }

  uploadData(clientReferrals: any[], dryRun: boolean, maxReferrals: number) {
    const createQuery = /* GraphQL */ `
      mutation bulkCreateReferrals(
        $clientReferrals: [BulkClientReferalInput]
        $autoMatch: Boolean
        $dryRun: Boolean
        $maxReferrals: Int
      ) {
        createReferrals(
          input: {
            clientReferrals: $clientReferrals
            autoMatch: $autoMatch
            dryRun: $dryRun
            maxReferrals: $maxReferrals
          }
        ) {
          errors {
            code
            field
            message
          }
          results {
            status
            priorClient {
              firstName
              lastName
            }
            referral {
              client {
                firstName
                lastName
              }
            }
            referralCreated
            error {
              code
              field
              message
              clients {
                edges {
                  node {
                    id
                    externalId
                    firstName
                    lastName
                    locations {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                    birthday
                  }
                }
              }
            }
            referral {
              id
            }
          }
        }
      }
    `;

    const variables = {
      clientReferrals,
      dryRun,
      maxReferrals,
      autoMatch:
        this.tableDataService.providerMatching.selection === 'use_previous',
    };

    return this.pLGraphQL
      .mutate(createQuery, variables, { debug: false, suppressError: true })
      .toPromise()
      .catch((err: any) => {
        console.error('mutate err: ', err);
        const referrals = clientReferrals.map(ref => {
          return { ...ref.client, ...ref.referral };
        });
        // TODO - incorrect throwing of error
        throw { errorMessage: err, data: referrals };
      });
  }
}
